module.exports = [{
      plugin: require('../../../.yarn/__virtual__/gatsby-plugin-gatsby-cloud-virtual-5dfaa63473/4/buildhome/.yarn_cache/gatsby-plugin-gatsby-cloud-npm-4.19.0-2129f5d294-40cdf7dadd.zip/node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../.yarn/__virtual__/gatsby-plugin-material-ui-virtual-96fa02bc5f/4/buildhome/.yarn_cache/gatsby-plugin-material-ui-npm-4.1.0-2938ee1c08-f1d38d2e50.zip/node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"pathToEmotionCacheProps":"src/emotion-cache-props"},
    },{
      plugin: require('../../../.yarn/__virtual__/gatsby-plugin-manifest-virtual-4a8c687819/4/buildhome/.yarn_cache/gatsby-plugin-manifest-npm-4.19.0-5c73f87aea-bc47920497.zip/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Overmoon","short_name":"Overmoon","start_url":"/","background_color":"#0C1D3F","theme_color":"#0C1D3F","display":"minimal-ui","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"ef10ac479d24d8f8969bdf79445d0564"},
    },{
      plugin: require('../../../.yarn/__virtual__/gatsby-plugin-segment-js-virtual-c141a02adf/4/buildhome/.yarn_cache/gatsby-plugin-segment-js-npm-3.7.1-69054e5b34-801e02268b.zip/node_modules/gatsby-plugin-segment-js/gatsby-browser.js'),
      options: {"plugins":[],"prodKey":"FgzgG53nc1JwdIU8DEzvHQMgBEvBjkqB","devKey":"FgzgG53nc1JwdIU8DEzvHQMgBEvBjkqB","trackPage":false},
    },{
      plugin: require('../../../.yarn/__virtual__/@sentry-gatsby-virtual-7e83662f7c/4/buildhome/.yarn_cache/@sentry-gatsby-npm-6.19.7-98559ac9b4-bf2192507c.zip/node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://89bd082ede2c9fd45ac4ba3bbb10ebe0@o925641.ingest.sentry.io/4505789505536000","sampleRate":1,"environment":"production","release":"1234","ignoreErrors":["ChunkLoadError","ResizeObserver loop limit exceeded","ResizeObserver loop completed with undelivered notifications."]},
    },{
      plugin: require('../../../.yarn/__virtual__/gatsby-plugin-google-tagmanager-virtual-dcc5641732/4/buildhome/.yarn_cache/gatsby-plugin-google-tagmanager-npm-3.14.0-2e46f23560-8a41cc2a3c.zip/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-56LDZH6","includeInDevelopment":true,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../../../.yarn/__virtual__/gatsby-plugin-fix-fouc-virtual-afbe76e719/4/buildhome/.yarn_cache/gatsby-plugin-fix-fouc-npm-1.0.4-dd5d8049c1-3d2ee50633.zip/node_modules/gatsby-plugin-fix-fouc/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../.yarn/unplugged/gatsby-virtual-bdc248802a/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
